.img-el-upload {
  margin: 0 auto;
  width: 88px;
  height: 88px;
}
 /deep/ .el-upload {
  width: 100%;
  height: 100%;
}
.up_video {
  width: 80px;
}
.up_video /deep/ .el-upload {
  border: 0 !important;
}
.upFileUrl {
  line-height: 20px;
}
.upFileUrl:hover {
  cursor: pointer;
  color: #5c6be8;
}
