





































































































































































































































































































































.ylfile {
  /deep/ .el-form-item__content {
    display: flex;
  }
}

.img-el-upload {
  margin: 0 auto;
  width: 88px;
  height: 88px;
}

/deep/.el-upload {
  width: 100%;
  height: 100%;
}

.up_video {
  width: 80px;

  /deep/ .el-upload {
    border: 0 !important;
  }
}

.upFileUrl {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  line-height: 20px;

  &:hover {
    cursor: pointer;
    color: #5c6be8;
  }
}
